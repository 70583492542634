import { useState } from 'react'

import useFetch from '@/hooks/useFetch'
import Section from '@/layouts/section'
import { fetchVacanciesSelection } from '@/lib/http/request'
import { VacanciesSelectionId, VacancyDetailsCard } from '@/interfaces/vacancy'
import { HomePageRvdb } from '@/interfaces/schema'
import Snackbar from '@/components/snackbar'
import IconWithTitle from '@/components/icon-with-title'
import { GRID, routes } from '@/lib/constants'
import { CTAStandardRvdbTallCardWithBtn } from '@/components/cards/cta/standard/rvdb'
import { ButtonLinkQuartiary } from '@/components/button'
import { LoaderBlockVacancies } from '@/sections/repeatable/vacancy-overview/loader'
import { API_ENDPOINTS } from '@/services/serverless/api/config'
import VacanciesList from '@/components/vacancy-overview/vacancy-list'

type Props = Pick<
  HomePageRvdb,
  | 'vacanciesSectionIcon'
  | 'vacanciesSectionTitle'
  | 'vacanciesCta'
  | 'vacancyIDsCollection'
>

const VacanciesAndCtaSection = ({
  vacanciesSectionIcon,
  vacanciesSectionTitle,
  vacanciesCta,
  vacancyIDsCollection,
}: Props) => {
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isErrorSnackbarOpen, setErrorSnackbarOpen] = useState<boolean>(false)
  const handleCloseErrorSnackbar = () => setErrorSnackbarOpen(false)

  const vidSelection = vacancyIDsCollection?.items.map(
    ({ vacancyId }) => vacancyId ?? ''
  ) ?? ['']

  const body: VacanciesSelectionId = {
    vidSelection,
  }

  const { data } = useFetch<VacancyDetailsCard[]>({
    fetcher: fetchVacanciesSelection(body),
    url: API_ENDPOINTS.VACANCY.SELECT,
    options: {
      onError(err) {
        setErrorSnackbarOpen(true)
        setErrorMessage(err.message)
      },
    },
  })

  return (
    <Section className={`${GRID} mb-[30px] lg:mb-[120px]`}>
      <IconWithTitle
        className="col-span-full xl:col-span-4"
        icon={vacanciesSectionIcon}
        title={vacanciesSectionTitle}
      />
      <ButtonLinkQuartiary
        className="col-span-full lg:col-start-1 xl:col-span-4"
        href={routes.rvdb.vacancies}
      >
        Alle vacatures
      </ButtonLinkQuartiary>
      {data && !errorMessage ? (
        <VacanciesList
          className="col-span-full xl:col-start-5 lg:col-end-13 grid grid-cols-2 md:grid-cols-4 lg:grid-cols-8 gap-lg"
          vacancies={data}
          ctaCard={
            <CTAStandardRvdbTallCardWithBtn
              className={`vacanciesAndCta__card`}
              title={vacanciesCta?.title ?? ''}
              description={vacanciesCta?.description ?? ''}
              buttonText={vacanciesCta?.buttonText ?? ''}
              buttonLink={vacanciesCta?.buttonPath ?? ''}
            />
          }
        />
      ) : (
        <LoaderBlockVacancies />
      )}
      <Snackbar
        message={errorMessage}
        isOpen={isErrorSnackbarOpen}
        onClose={handleCloseErrorSnackbar}
      />
    </Section>
  )
}

export default VacanciesAndCtaSection
