import { useId } from 'react'

import Section from '@/layouts/section'
import StandardCard from '@/components/cards/services/standard'
import CTANewsLetterCard from '@/components/cards/cta/newsletter'
import { GRID } from '@/lib/constants'
import { HomePageRvdb } from '@/interfaces/schema'

const IntroCardsSection = ({
  introductoryCardsCollection,
  newsletterCard,
}: Pick<HomePageRvdb, 'introductoryCardsCollection' | 'newsletterCard'>) => {
  const id = useId()

  return (
    <Section className={`${GRID} mb-[30px] lg:mb-[120px]`}>
      {introductoryCardsCollection?.items.map(
        ({ icon, title, description, buttonText, buttonPath }, index) => (
          <StandardCard
            key={`${index}-${id}`}
            className="col-span-2 md:col-span-3 lg:col-span-4"
            icon={icon}
            title={title}
            description={description}
            buttonText={buttonText}
            buttonPath={buttonPath}
          />
        )
      )}
      <CTANewsLetterCard
        className="col-span-2 md:col-span-3 lg:col-span-4"
        title={newsletterCard?.title}
        description={newsletterCard?.description}
        newsletterCode={newsletterCard?.newsletterCode}
      />
    </Section>
  )
}

export default IntroCardsSection
