import { GetStaticPropsContext } from 'next'

import PageProps from '@/interfaces/page'
import { HomePageRvdb } from '@/interfaces/schema'
import Main from '@/layouts/main'
import {
  getHomePageRvdbData,
  getRvdbNavigationData,
} from '@/services/contentful/api'
import HomePageBanner from '@/sections/rvdb/homepage/homepage-banner'
import ContentSmallSection from '@/sections/repeatable/content-small'
import IntroCardsSection from '@/sections/rvdb/homepage/intro-cards'
import OrganizationCardsSection from '@/sections/rvdb/organization-cards'
import ReviewSection from '@/sections/repeatable/review'
import ClientsSection from '@/sections/repeatable/clients'
import { FaqSection, StoriesSection } from '@/sections/repeatable'
import VacanciesAndCtaSection from '@/sections/repeatable/vacancies-and-cta/four-cards'

const HomePage = ({ data }: PageProps<HomePageRvdb>) => {
  const {
    bannerImage,
    bannerSlogan,
    bannerTitle,
    bannerSubtitle,
    displayIntroductionSection,
    introductionIcon,
    introductionTitle,
    introductionDescription,
    displayIntroductoryCardsSection,
    introductoryCardsCollection,
    newsletterCard,
    displayServicesCardsSection,
    servicesCardsSectionIcon,
    servicesCardsSectionTitle,
    servicesCardsListCollection,
    displayReviewSection,
    reviewSectionIcon,
    reviewSectionTitle,
    testimonialsCollection,
    displayVacanciesSection,
    vacanciesSectionIcon,
    vacanciesSectionTitle,
    vacanciesCta,
    vacancyIDsCollection,
    displayClientsSection,
    clientsSectionIcon,
    clientsSectionTitle,
    clientsList,
    displayStoriesSection,
    storiesSectionIcon,
    storiesSectionTitle,
    storiesCollection,
    storiesButtonText,
    displayFaqSection,
    faqIcon,
    faqTitle,
    faqSubtitle,
    faqsCollection,
  } = data

  return (
    <Main>
      <HomePageBanner
        bannerImage={bannerImage}
        bannerSlogan={bannerSlogan}
        bannerTitle={bannerTitle}
        bannerSubtitle={bannerSubtitle}
      />
      {displayIntroductionSection && (
        <ContentSmallSection
          contentIcon={introductionIcon}
          contentTitle={introductionTitle}
          contentBody={introductionDescription}
          id="home-content"
        />
      )}
      {displayIntroductoryCardsSection && (
        <IntroCardsSection
          introductoryCardsCollection={introductoryCardsCollection}
          newsletterCard={newsletterCard}
        />
      )}
      {displayServicesCardsSection && (
        <OrganizationCardsSection
          isConsultancy
          icon={servicesCardsSectionIcon}
          title={servicesCardsSectionTitle}
          cardsCollection={servicesCardsListCollection}
        />
      )}
      {displayReviewSection && (
        <ReviewSection
          reviewSectionIcon={reviewSectionIcon}
          reviewSectionTitle={reviewSectionTitle}
          testimonialsCollection={testimonialsCollection}
        />
      )}
      {displayVacanciesSection && (
        <VacanciesAndCtaSection
          vacanciesSectionIcon={vacanciesSectionIcon}
          vacanciesSectionTitle={vacanciesSectionTitle}
          vacanciesCta={vacanciesCta}
          vacancyIDsCollection={vacancyIDsCollection}
        />
      )}
      {displayClientsSection && (
        <ClientsSection
          clientsSectionIcon={clientsSectionIcon}
          clientsSectionTitle={clientsSectionTitle}
          clientsList={clientsList}
        />
      )}
      {displayStoriesSection && (
        <StoriesSection
          storiesSectionIcon={storiesSectionIcon}
          storiesSectionTitle={storiesSectionTitle}
          storiesCollection={storiesCollection}
          storiesButtonText={storiesButtonText}
        />
      )}
      {displayFaqSection && (
        <FaqSection
          faqIcon={faqIcon}
          faqTitle={faqTitle}
          faqSubtitle={faqSubtitle}
          faqsCollection={faqsCollection}
        />
      )}
    </Main>
  )
}

export async function getStaticProps({ preview }: GetStaticPropsContext) {
  const isPreview: boolean = !!preview ?? false

  const { data: pageData, error: pageError } = await getHomePageRvdbData(
    isPreview
  )

  const { data: navData, error: navError } = await getRvdbNavigationData(
    isPreview
  )

  return {
    props: {
      data: { navData, pageData },
      error: { pageError, navError },
    },
    revalidate: 60,
  }
}

export default HomePage
