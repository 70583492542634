import { useId } from 'react'

import IconWithTitle from '@/components/icon-with-title'
import {
  Asset,
  ConsultancyDetail,
  Maybe,
  TalentProgramDetail,
} from '@/interfaces/schema'
import Section from '@/layouts/section'
import { GRID, routes } from '@/lib/constants'
import OrganizationsCard from '@/components/cards/services/organizations'

interface OrganizationCardsSectionProps {
  isConsultancy: boolean
  icon?: Asset
  title?: string
  cardsCollection?: {
    items?: Array<Maybe<ConsultancyDetail>> | Array<Maybe<TalentProgramDetail>>
  }
  showAllButtonUrl?: string
  id?: string
}

const OrganizationCardsSection = ({
  isConsultancy,
  title,
  icon,
  cardsCollection,
  showAllButtonUrl,
  id = '',
}: OrganizationCardsSectionProps) => {
  const cardId = useId()
  const route = isConsultancy
    ? routes.rvdb.rvdbConsultancy
    : routes.rvdb.talentProgram.talentProgram

  return (
    <Section id={id} className={`${GRID} mb-[30px] lg:mb-[120px]`}>
      <IconWithTitle
        icon={icon}
        title={title}
        showAllButtonUrl={showAllButtonUrl}
      />
      {cardsCollection?.items?.map((item, index) => {
        const { bannerImage, bannerTitle, introduction, cardButtonText, slug } =
          item

        // there are either 2 or 3 cards in this content model. On large screens, we render these so that the cards fit into one row
        // 12 here is the number of columns for large screens
        const colSpan = 12 / (cardsCollection?.items?.length ?? 0)
        return (
          <OrganizationsCard
            className={`col-span-full md:col-span-3 lg:col-span-${colSpan}`}
            key={`${cardId}-${index}`}
            image={bannerImage}
            description={introduction ?? ''}
            title={bannerTitle ?? ''}
            buttonText={cardButtonText ?? ''}
            buttonLink={`${route}/${slug ?? ''}`}
          />
        )
      })}
    </Section>
  )
}

export default OrganizationCardsSection
