import { useRouter } from 'next/router'

import { ButtonLinkPrimaryArrow, ButtonPrimaryArrow } from '@/components/button'
import { H1, H2, P2 } from '@/components/typography'
import { useExtraSmallScreen, useMediumScreen } from '@/hooks/useMediaQuery'
import { HomePageRvdb } from '@/interfaces/schema'
import Block from '@/layouts/block'
import Section from '@/layouts/section'
import getBgImgStyles from '@/lib/bg-image'
import { GRID, routes } from '@/lib/constants'

const HomePageSlogan = ({
  bannerSlogan,
  isMediumScreen,
}: Pick<HomePageRvdb, 'bannerSlogan'> & { isMediumScreen: boolean }) => {
  const sloganHClassName = isMediumScreen ? 'H1' : 'H1s'
  return <p className={`homepageSlogan ${sloganHClassName}`}>{bannerSlogan}</p>
}

const HomePageBanner = ({
  bannerImage,
  bannerSlogan,
  bannerTitle,
  bannerSubtitle,
}: Pick<
  HomePageRvdb,
  'bannerImage' | 'bannerSlogan' | 'bannerTitle' | 'bannerSubtitle'
>) => {
  const { push } = useRouter()
  const isExtraSmallScreen = useExtraSmallScreen()
  const isMediumScreen = useMediumScreen()
  const imgHeight = isExtraSmallScreen ? 215 : isMediumScreen ? 315 : 615
  const imgWidth = isMediumScreen ? 'calc(100% + 10px)' : 'calc(100% + 80px)'

  const bg = getBgImgStyles({
    url: bannerImage?.url ?? '',
    height: imgHeight,
    width: imgWidth,
    parallax: false,
    gradientOverlay: true,
  })

  const goToContent = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    push({ pathname: '/', hash: 'home-content' }, undefined, {
      scroll: false,
    })
  }

  return (
    <Section
      styles={bg}
      className="relative rounded-xs md:rounded-sm w-full !max-w-[1360px] mb-[120px] lg:mb-[170px]"
    >
      <HomePageSlogan
        bannerSlogan={bannerSlogan}
        isMediumScreen={isMediumScreen}
      />
      {!isMediumScreen && (
        <div className="flex items-center gap-[20px] absolute left-[80px] bottom-[40px]">
          <ButtonPrimaryArrow
            className="rotate-90"
            version="b"
            onClick={goToContent}
          />
          <P2 className="text-white">Lees meer</P2>
        </div>
      )}
      <Block className={`${GRID}`}>
        <div className="flex flex-col justify-center mx-[10px] md:mx-[20px] lg:mx-[40px] h-[123px] md:h-[145px] lg:h-[190px] col-span-full lg:col-start-7 lg:col-end-13 mt-[170px] sm:mt-[270px] lg:mt-[506px] rounded-xs md:rounded-sm bg-neutral-white px-[30px] sm:px-10 border-2 border-neutrals-100">
          <H1>{bannerTitle}</H1>
          <div className="flex w-full  justify-between">
            <H2 className="mt-[5px]">{bannerSubtitle}</H2>
            <ButtonLinkPrimaryArrow href={routes.rvdb.vacancies} />
          </div>
        </div>
      </Block>
    </Section>
  )
}

export default HomePageBanner
