import {
  FiltersLoader,
  VacanciesCardLoader,
} from '@/components/loader/skeleton'
import Block from '@/layouts/block'

export const LoaderBlockVacancies = () => {
  return (
    <Block className="col-start-1 col-end-3 md:col-start-3 md:col-end-7 lg:col-start-5 lg:col-end-13 grid grid-cols-8 gap-lg w-full">
      <VacanciesCardLoader className="md:col-start-1 md:col-end-5  bg-neutral-white rounded-sm" />
      <VacanciesCardLoader className="md:col-start-5 md:col-end-9  bg-neutral-white rounded-sm" />
      <VacanciesCardLoader className="md:col-start-1 md:col-end-5  bg-neutral-white rounded-sm" />
      <VacanciesCardLoader className="md:col-start-5 md:col-end-9  bg-neutral-white rounded-sm" />
    </Block>
  )
}

export const LoaderBlockFilters = () => (
  <Block>
    <FiltersLoader />
  </Block>
)
