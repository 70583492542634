import { ReactNode, useId } from 'react'

import { VacancyDetailsCard } from '@/interfaces/vacancy'
import Block from '@/layouts/block'
import VacancyCard from '@/components/cards/vacancy'

interface VacanciesListProps {
  className?: string
  vacancies: VacancyDetailsCard[] | null
  ctaCard: ReactNode
}

const VacanciesList = ({
  className = '',
  vacancies,
  ctaCard,
}: VacanciesListProps) => {
  const vacancyCardId = useId()

  if (!vacancies) return <></>

  return (
    <Block className={`${className}`}>
      {vacancies.map(({ uid, ...rest }, index) => {
        const order = index < 4 ? index + 1 : index + 2
        return (
          <VacancyCard
            className="col-span-full md:col-span-2 lg:col-span-4"
            uid={uid}
            key={`${vacancyCardId}-${index}`}
            order={order}
            {...rest}
          />
        )
      })}
      {ctaCard}
    </Block>
  )
}

export default VacanciesList
