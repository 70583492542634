import React from 'react'
import ContentLoader, { IContentLoaderProps } from 'react-content-loader'

export const ProfileLoader = (props: IContentLoaderProps) => {
  return (
    <ContentLoader
      height={200}
      width={400}
      viewBox="0 0 400 150"
      backgroundColor="#d9d9d9"
      foregroundColor="#ecebeb"
      className="loader"
      {...props}
    >
      <rect x="15" y="15" rx="4" ry="4" width="130" height="10" />
      <rect x="155" y="15" rx="3" ry="3" width="130" height="10" />
      <rect x="295" y="15" rx="3" ry="3" width="90" height="10" />
      <rect x="15" y="50" rx="3" ry="3" width="90" height="10" />
      <rect x="115" y="50" rx="3" ry="3" width="60" height="10" />
      <rect x="185" y="50" rx="3" ry="3" width="200" height="10" />
      <rect x="15" y="90" rx="3" ry="3" width="130" height="10" />
      <rect x="160" y="90" rx="3" ry="3" width="120" height="10" />
      <rect x="290" y="90" rx="3" ry="3" width="95" height="10" />
      <rect x="15" y="130" rx="3" ry="3" width="130" height="10" />
      <rect x="160" y="130" rx="3" ry="3" width="225" height="10" />
    </ContentLoader>
  )
}

export const VacanciesCardLoader = (props: IContentLoaderProps) => {
  return (
    <ContentLoader
      height={530}
      width={406.7}
      viewBox="0 0 406.7 530"
      backgroundColor="#d9d9d9"
      foregroundColor="#ecebeb"
      className="loader"
      {...props}
    >
      <rect x="40" y="40" rx="4" ry="4" width="310" height="20" />
      <rect x="40" y="70" rx="4" ry="4" width="310" height="20" />
      <rect x="40" y="120" rx="3" ry="3" width="160" height="10" />
      <rect x="40" y="140" rx="3" ry="3" width="160" height="10" />
      <rect x="40" y="200" rx="3" ry="3" width="310" height="10" />
      <rect x="40" y="220" rx="3" ry="3" width="310" height="10" />
      <rect x="40" y="240" rx="3" ry="3" width="310" height="10" />
      <rect x="40" y="260" rx="3" ry="3" width="310" height="10" />
      <rect x="40" y="280" rx="3" ry="3" width="310" height="10" />
      <rect x="40" y="300" rx="3" ry="3" width="310" height="10" />
      <rect x="40" y="320" rx="3" ry="3" width="310" height="10" />
      <rect x="40" y="340" rx="3" ry="3" width="310" height="10" />
      <rect x="40" y="360" rx="3" ry="3" width="310" height="10" />
      <rect x="40" y="430" rx="4" ry="4" width="310" height="2" />
      <rect x="300" y="460" rx="4" ry="4" width="60" height="40" />
      <rect x="40" y="460" rx="4" ry="4" width="200" height="40" />
    </ContentLoader>
  )
}

export const EmployeeCardLoader = (props: IContentLoaderProps) => {
  return (
    <ContentLoader
      height={330}
      width="100%"
      viewBox="0 0 250 330"
      backgroundColor="#d9d9d9"
      foregroundColor="#ecebeb"
      className="loader"
      {...props}
    >
      <rect x="15" y="20" rx="4" ry="4" width="190" height="10" />
      <rect x="15" y="60" rx="3" ry="3" width="190" height="10" />
      <rect x="15" y="100" rx="3" ry="3" width="145" height="10" />
      <rect x="15" y="140" rx="3" ry="3" width="175" height="10" />
      <rect x="15" y="180" rx="3" ry="3" width="175" height="10" />
      <rect x="15" y="220" rx="3" ry="3" width="175" height="10" />
      <rect x="15" y="260" rx="4" ry="4" width="190" height="10" />
      <rect x="15" y="300" rx="4" ry="4" width="190" height="10" />
    </ContentLoader>
  )
}

export const FiltersLoader = (props: IContentLoaderProps) => {
  return (
    <ContentLoader
      height={330}
      width={400}
      viewBox="0 0 400 330"
      foregroundColor="#d9d9d9"
      className="loader"
      {...props}
    >
      <rect x="0" y="20" rx="4" ry="4" width="150" height="10" />
      <rect x="0" y="60" rx="3" ry="3" width="150" height="10" />
      <rect x="0" y="100" rx="3" ry="3" width="150" height="10" />
      <rect x="0" y="140" rx="3" ry="3" width="150" height="10" />
      <rect x="0" y="180" rx="3" ry="3" width="150" height="10" />
      <rect x="0" y="220" rx="3" ry="3" width="150" height="10" />
    </ContentLoader>
  )
}

export const VacancyDetailTitleLoader = (props: IContentLoaderProps) => {
  return (
    <ContentLoader
      height={30}
      width="100%"
      viewBox="0 0 400 30"
      foregroundColor="#d9d9d9"
      className="loader"
      {...props}
    >
      <rect x="0" y="10" rx="3" ry="3" width="80%" height="10" />
    </ContentLoader>
  )
}

export const VacancyDetailGenInfoLoader = (props: IContentLoaderProps) => {
  return (
    <ContentLoader
      height={30}
      width="100%"
      viewBox="0 0 400 30"
      foregroundColor="#d9d9d9"
      className="loader"
      {...props}
    >
      <rect x="0" y="10" rx="3" ry="3" width="80%" height="10" />
    </ContentLoader>
  )
}

export const VacancyDetailMainInfoLoader = (props: IContentLoaderProps) => {
  return (
    <ContentLoader
      height={500}
      width="100%"
      viewBox="0 0 100% 500"
      backgroundColor="#d9d9d9"
      foregroundColor="#ecebeb"
      className="loader"
      {...props}
    >
      <rect x="15" y="20" rx="4" ry="4" width="80%" height="10" />
      <rect x="15" y="60" rx="3" ry="3" width="70%" height="10" />
      <rect x="15" y="100" rx="3" ry="3" width="60%" height="10" />
      <rect x="15" y="140" rx="3" ry="3" width="50%" height="10" />
      <rect x="15" y="180" rx="3" ry="3" width="50%" height="10" />
      <rect x="15" y="220" rx="3" ry="3" width="40%" height="10" />
      <rect x="15" y="260" rx="4" ry="4" width="70%" height="10" />
      <rect x="15" y="300" rx="4" ry="4" width="60%" height="10" />
      <rect x="15" y="340" rx="4" ry="4" width="60%" height="10" />
      <rect x="15" y="380" rx="4" ry="4" width="50%" height="10" />
      <rect x="15" y="420" rx="4" ry="4" width="60%" height="10" />
      <rect x="15" y="460" rx="4" ry="4" width="30%" height="10" />
    </ContentLoader>
  )
}
