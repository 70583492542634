import useSWR from 'swr'
import { BareFetcher, PublicConfiguration } from 'swr/dist/types'
import { useLocalStorage } from 'usehooks-ts'

import fetchData, { FetcherProps } from '@/lib/http/request'
import initLocalStorageData from '@/lib/factory/local-storage'

type DefaultFetcher<DataType> = (p: FetcherProps<DataType>) => Promise<DataType>

type FetchConfig<DataType> = Partial<
  PublicConfiguration<DataType, Error, BareFetcher<DataType>>
>

export default function useFetch<DataType>({
  url,
  fetcher = fetchData,
  options,
}: {
  url: string | null
  fetcher?: DefaultFetcher<DataType>
  options?: Partial<PublicConfiguration<DataType, Error, BareFetcher<DataType>>>
}) {
  const config = {
    shouldRetryOnError: false,
    ...options,
  } as FetchConfig<DataType>

  const [{ idToken }] = useLocalStorage('rvdb', initLocalStorageData)

  const { data, error } = useSWR<DataType, Error>(
    { url, idToken },
    url ? fetcher : null,
    config
  )

  return { data, error, loading: !data && !error }
}
